import React from 'react';
import { PageProps } from 'gatsby';
import SEO from '~/components/seo';
import { SignInPage } from '~/components/SignIn';

const SignIn: React.FC<PageProps> = props => (
  <>
    <SEO pageContext={props.pageContext} title="Account.signIn" />
    <SignInPage {...props} />
  </>
);

export default SignIn;
